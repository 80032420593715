<template>
  <div class="columnList">
    <div class="card">
      <!--      <div class="title" v-html="title"></div>-->
      <img src="../../assets/img/second/detailBg.jpg" class="opportbg" />
    </div>
    <div class="conent">
      <!-- 栏目页大图 -->
      <div class="lbt">
<!--        <img src="../../assets/img/banner.jpg" />-->
      </div>
      <div style="width: 71.875vw;" v-show="treelist && treelist.length && !hide">
        <div >
          <ul class="tabs">
            <div style="position: absolute;height: 6.25vw;left: 0.10417vw;top: -3.125vw;background-color: #c13230;width: 10.41667vw;font-size: 1.5625vw;text-align: center;line-height: 6.25vw;color: #fff;">
              <!--        {{title}}-->
              新闻资讯
            </div>
            <li class="tab-item"
              v-for="(item, index) in treelist"
              :key="index"
              :id="item.id"
              :class="cuIdx === index ? 'active' : ''"
                @click="item.erOriginLink ? open(item.erOriginLink) : reload('common', index, item)"
            >
              {{ item.name }}
              <div v-if="cuIdx === index" style="position: absolute;bottom: 0;width: 100%;display: flex;justify-content: center;">
                <div style="width: 2.08333vw;background-color: #c13230;height: 0.10417vw;"></div>
              </div>
<!--              <template v-if="item.erOriginLink">-->
<!--                <a-->
<!--                  v-if="item.erOriginLink"-->
<!--                  :href="item.erOriginLink"-->
<!--                  target="_blank"-->
<!--                >-->
<!--                  {{ item.name }}</a-->
<!--                >-->
<!--              </template>-->
<!--              <span-->
<!--                v-else-->
<!--                @click="reload('common', index, item)"-->
<!--                :title="item.name"-->
<!--                >{{ item.name }}</span-->
<!--              >-->
            </li>
            <!-- <li
              v-for="(item, index) in tablist"
              :key="index"
              :id="item.id"
              :class="cuIdx === index.id ? 'tjlm_on' : ''"
            >
              <a v-if="item.url" :href="item.url" target="_blank">{{
                item.id
              }}</a>
              <span v-else :title="item.id">{{ item.id }}</span>
            </li> -->
          </ul>
        </div>
      </div>
      <!-- <listCommonVue
        v-if="currentTabComponent == 'common'"
        :treeData="curData"
        :pageTitle="curPageTitle"
        class="w1380 lmy"
      ></listCommonVue> -->
<!--      <div v-if="hide" style="text-align: left;font-size: 1.04167vw;padding: 0.52083vw;margin-top: 1.04167vw;color: #a73827;width: 100%;">{{ title }}</div>-->
      <component
        ref="currentTabComponent"
        :is="currentTabComponent"
        class="w1380 lmy"
      ></component>
    </div>
  </div>
</template>

<script>
import jcxz from "./components/jcxz.vue";
import bssds from "./components/bssds.vue";
import zjjs from "./components/zjjs.vue";
import wjzj from "./components/wjzj.vue";
import qnyc from "./components/qnyc.vue";
// import listCommonVue from "./components/listCommon.vue";
export default {
  components: {
    // listCommonVue,
    jcxz,
    bssds,
    zjjs,
    wjzj,
    qnyc,
  },
  data() {
    return {
      cuIdx: 0,
      curPageTitle: "",
      curData: [],
      treelist: [],
      tablist: [
        // {
        //   id: "杰出学者",
        //   components: "jcxz",
        // },
        // {
        //   id: "博士生导师",
        //   components: "bssds",
        // },
        // {
        //   id: "专家教授",
        //   components: "zjjs",
        // },
        // {
        //   id: "外籍专家",
        //   components: "wjzj",
        // },
        // {
        //   id: "青年英才",
        //   components: "qnyc",
        // },
        // {
        //   id: "师德师风",
        //   url: "http://shide.bisu.edu.cn",
        // },
        // {
        //   id: "教师发展",
        //   url: "http://cfd.bisu.edu.cn/",
        // },
      ],
      currentTabComponent: "jcxz",
      hide: false,
    };
  },
  created() {
    if (this.college === 'zgggzcfyyjy') {
      const language = localStorage.getItem('language')
      this.college = language ==='en' ? this.college + 'en' : this.college
    }
    this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
    this.currentTabComponent = "jcxz";
    this.hide = !!this.$route.query.hide;
    this.getTreeList();
  },
  watch: { // 监听,当路由发生变化的时候执行
    '$route.query': {
      // immediate: true,
      handler(newVal, oldVal) {
        this.cuIdx = Number(newVal.id);
        this.hide = !!this.$route.query.hide;
        this.getTreeList();
      }
    }
  },
  methods: {
    async getTreeList() {
      // const res = await this.API.basic.getAllColumn({channelId: 'hqweb'});
      const res = await this.API.basic.getAllColumn({channelId: this.$store.state.college});
      console.log('resresres', res)
      if (res.data && res.data.length > 0) {
        let findColumn = res.data.findIndex((i) => {
          // return i.uuid == this.$columnIdFile.szdw;
          return i.name == (this.$route.query.columnName || '师资队伍');
        });
        findColumn != "-1"
          ? (this.treelist = res.data[findColumn].children)
          : (this.treelist = []);
        this.treelist.forEach(async (i) => {
          if (
            i.uuid == this.$columnIdFile.szdwsdsf ||
            i.uuid == this.$columnIdFile.szdwjsfz
          ) {
            const params = {
              pageNumber: 0,
              pageSize: 1,
              columnIds: i.uuid,
            };
            const res = await this.API.basic.getTeachers(params);
            if(res?.data?.content){
              i.erOriginLink = res.data?.content[0].mapProperties.erOriginLink;
            }

          } else {
            i.erOriginLink = null;
          }
          if (i.name === "师德师风") {
            i.erOriginLink = "http://shide.bisu.edu.cn";
          }
          if (i.name === "教师发展") {
            i.erOriginLink = "http://cfd.bisu.edu.cn/";
          }
          this.$forceUpdate();
        });
        this.$refs.currentTabComponent.content = this.treelist[this.cuIdx];
        // this.currentTabComponent = "common";
        // if (this.$route.query.id) {
        //   this.curPageTitle = this.treelist[this.cuIdx].columnName;
        //   this.curData = this.treelist[this.cuIdx].childList;
        // }
        console.log('treelist', this.treelist)
      } else {
        // this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
        // this.currentTabComponent = this.tablist[this.cuIdx].components;
      }
    },
    reload(component, index, data) {
      this.$refs.currentTabComponent.content = data;
      this.curPageTitle = data ? data.columnName : "";
      this.curData = data ? data.childList : [];
      this.cuIdx = index;
      // this.currentTabComponent = component;
    },
    open(url) {
      window.open(url);
    }
  },
};
</script>

<style lang="scss" scoped>
.columnList {
  padding-bottom: 50px;
  .conent .lbt {
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
}
.tjlm ul li.tjlm_on a {
  color: #fff;
}
//.tabs {
//  width: 1380px;
//  position: absolute;
//  top: 0;
//  left: 270px;
//  background: white;
//}

.tabs {
  width: 1380px;
  //padding: 20px 0;
  display: flex;
  justify-content: center;
  background: #fff;
  //position: absolute;
  //top: 500px;
  position: absolute;
  top: 700px;
  left: 267px;
  height: 60px;
  border-bottom: 1px solid rgb(238, 238, 238);
  .tab-item {
    margin: 0px 40px;
    //height: 120px;
    //border: 1px solid #c13230;
    font-weight: 400;
    font-size: 20px;
    color: #000;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    &:hover {
      //background-color: #c13230;
      color: #c13230;
      a {
        //background-color: #c13230;
        color: #c13230;;
      }
    }
    a {
      font-weight: 400;
      font-size: 20px;
      color: #c13230;
    }
  }
  .active {
    //background: #c13230;
    color: #c13230;
  }
}
</style>
